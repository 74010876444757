(function($) {

	const images = document.getElementsByClassName('hero-image');
	new simpleParallax(images, {
		delay: 0,
		orientation: 'up',
		scale: 1.3,
		overflow: true,
	});

	// Script
	var lastScroll = 0;
	$(window).on('scroll',function() {
	    var scroll = $(window).scrollTop();
	    if(lastScroll - scroll > 0) {
	        $("header").addClass("scrolling-up");
	    } else {
	        $("header").removeClass("scrolling-up");
	    }
	    lastScroll = scroll;
	});

	$(window).scroll(function() {
		var scroll = $(window).scrollTop();

		// Change header position
		if(scroll > 50){
			$('header').addClass('scrolling');
		}else{
			$('header').removeClass('scrolling');
		}

		// Change header position
		if(scroll < 350){
			$('header').removeClass('scrolling-up');
		}

	});



	/* Lightbox
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	// Open

	$( ".lightbox-button" ).click(function() {
		// stopScroll();
		var lightbox_id = $( this ).prop('id');
		$( "#" + lightbox_id + "-content" ).addClass("active");
		if (lightbox_id == 'lightbox-search'){
			setTimeout(searchFocus, 400);
		}
	});

	// Close

	function close_lightbox() {
		// startScroll();
		$( ".custom-lightbox" ).removeClass("active");
	}

	$( ".close-btn" ).click(function() {
		close_lightbox();
	});

	$(document).on('click', function(event) {
		if ($(event.target).has('.lightbox-content').length) {
			close_lightbox();
		}
	});

	/* Search Lightbox
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	// Search Focus on input

	function searchFocus(){
		$("input#s").focus();
	}






	/* To the top
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	$(".back-to-top").click(function(){
      $("html, body").animate({ scrollTop: 0 }, 600);
      return false;
  });



	/* Swipe Menu (Custom Moblie Menu)
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	$('#mobile-menu').mmenu({
    extensions: ["border-full", "pagedim-black", "position-right"],
		navbar:
		{
			title	:	"Menu"
		},
		offCanvas: {
				pageSelector: ".site-wrap"
		},
		scrollBugFix: {
    	fix:true,
    },
		slidingSubmenus: true,
  });





	/* Sticky Header
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	// var headerHeight = $('.top-nav').outerHeight() + $('header').outerHeight();
	var headerHeight = $('header').outerHeight();

	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		headerHeight = $('header').outerHeight() + $('header').outerHeight();

		if(scroll > headerHeight){
			$('header').addClass('Sticky');
			$('body').addClass('menu_is_sticky');
		}else{
			$('header').removeClass('Sticky');
			$('body').removeClass('menu_is_sticky');
		}

		if(scroll > 700){
			$('.back-to-top').addClass('visible');
		}else{
			$('.back-to-top').removeClass('visible');
		}

		if(scroll > 100){
			$('.arrow-container').addClass('invisible');
		} else{
			$('.arrow-container').removeClass('invisible');
		}


	});




	$( ".mburger-mobilemenu" ).click(function() {
		$(".mm-menu").removeClass('mm-menu_opened');
		$("html").removeAttr("class");
		$(".site-wrap").removeAttr("style");
	});

	$( ".mburger-normal" ).click(function() {
		$(".mm-menu").addClass('mm-menu_opened');
		$("html").addClass("mm-wrapper_opened");
		$("html").addClass("mm-wrapper_blocking");
		$("html").addClass("mm-wrapper_background");
		$("html").addClass("mm-wrapper_opening");
	});

})( jQuery );
